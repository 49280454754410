// ** Initial State
const initialState = {
  allData: [],
  data: null,
}

const emailtemplate = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_TEMPLATE':
      return { ...state, allData: action.data }
    case 'GET_DATA':
      return { ...state, data: action.data }
    case 'SET_DATA':
      return { ...state, data: null }
    case 'EMPTY_EMAIL_TEMPLATE':
      return { ...state, data: null }
    default:
      return { ...state }
  }
}
export default emailtemplate

// ** Initial State
const initialState = {
  allData: [],
  data: [],
  pricingData: [],
  amount: [],
  allExhibitors: [],
  promo_code:{},
  event_detail:{},
}

const bookingnew3 = (state = initialState, action) => {
  switch (action.type) {
    case 'BOOKING_LIST':
      return { ...state, allData: action.data}
    case 'CREATE_BOOKING':
      return { ...state, data: action.data}
    case 'BOOKINGREF':
      return { ...state, data: action.data }
    case 'CREATE_SURVEY':
      return { ...state, data: action.data }
    case 'CREATE_MEETING':
      return { ...state, data: action.data }
    case 'CREATE_PAYMENT':  
      return { ...state, data: action.data }
    case 'PRICING_LIST':
      return { ...state, pricingData: action.data }
    case 'CHECKAMOUNT':
      return { ...state, amount: action.data }
    case 'GET_ALL_EXHIBITORS':
      return { ...state, allExhibitors: action.data }
    case 'APPLY_PROMO_CODE':
      return { ...state, promo_code: action.data }
    case 'GET_EVENT_DETAILS':
      return { ...state, event_detail: action.data }
    case 'UPDATE_TICKET_DETAILS':
      return { ...state, ticket_details: action.data }
    default:
      return { ...state }
  }
}
export default bookingnew3

// ** Initial State
const initialState = {
  allAppSetting: [],
  appSetting: [],
  data: []
}

const appSetting = (state = initialState, action) => {
  switch (action.type) {   

    case 'GET_APPSETTING':
      return {
        ...state,
        data: action.data,
        allAppSetting:  action.data,
        appSetting: action.data
      }
    
    case 'UPDATE_APPSETTING':
      return {
        ...state,
        data: action.data,
        allAppSetting:  action.data,
        appSetting: action.data
      }
    default:
      return { ...state }
  }
}
export default appSetting

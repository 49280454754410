// ** Initial State
const initialState = {
  allData: [],
  data: null,
  memberForList: {
    "limit": 10,
    "page": 0,
    "total_pages": null,
    "total_records": null,
    "data": null
  },
  pageLoader:true
}

const member_reports = (state = initialState, action) => {
  // alert(action.type)
  switch (action.type) {
    case 'GET_ALL_MEMBERSHIP_DATA_WITH_PAGINATION':
      return {...state, memberForList: action.data, pageLoader:false}
    case 'GET_ALL_MEMBERSHIP':
      return {...state, allData: action.data, pageLoader:false}
    default:
      return { ...state }
  }
}
export default member_reports

// ** Initial State
const initialState = {
  userevents_data: [], 
  event_info: null, 
}

const userEvent = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_EVENTS':
      return { ...state, userevents_data: action.data }
    case 'GET_EVENTS_DATA':
      return { ...state,event_info: action.data }
    default:
      return { ...state }
  }
}
export default userEvent

// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import users from '@src/views/pages/user/store/reducer'
import imports from '@src/views/pages/imports/store/reducer'
import booking from '@src/views/pages/summit-register copy 4/store/reducer'
import userBooking from '@src/views/pages/user-booking/store/reducer'
import bookingnew from '@src/views/pages/summit-register copy 4/store/reducer'
import booking3 from '@src/views/pages/user-payments/store/reducer'
import bookingnew3 from '@src/views/pages/summit-register copy 4/store/reducer'
import invites from '@src/views/pages/invites/store/reducer'
import authorizenet from '@src/views/pages/summit-register copy 4/store-authorizenet/reducer'
import password from '@src/views/pages/authentication/store/reducer'
// import exhibitor from '@src/views/pages/exhibitors/store/reducer'
import registration from '@src/views/pages/registration/store/reducer'
import speakerpanelist from '@src/views/pages/speakerpanelist/store/reducer'
import abandoned from '@src/views/pages/abondend-user-payments/store/reducer'
import trash from '@src/views/pages/trash/store/reducer'
// import booths from '@src/views/pages/booth-crud/store/reducer'
import aboutus from '@src/views/pages/aboutUs/store/reducer'
import appSetting from '@src/views/pages/appSetting/store/reducer'
import event from '@src/store/reducer/EventReducer'
import category from '@src/store/reducer/CategoryReducer'
import emailtemplate from '@src/views/pages/email-template/store/reducer'
import posts from '@src/views/pages/posts/store/reducer'
//import advertisement from '@src/views/pages/advertisement/store/reducer'
//import survey from '@src/views/pages/survey/store/reducer'
import pushs from '@src/views/push/store/reducer'
//import conferenceevent from "@src/views/pages/conference-event/store/reducer"
import membership from "@src/views/pages/membership/store/reducer"
import promoCode from "@src/views/pages/promo-code/store/reducer"
import categoryList from "@src/views/pages/category/store/reducer"
import directory from "@src/views/pages/directory/store/reducer"
import organization from "@src/views/pages/organization/store/reducer"
import directoryui from "@src/views/pages/directory-ui/store/reducer"
//import pollsui from "@src/views/pages/polls-rating/store/reducer"
import userEvent from "@src/views/pages/user-events/store/reducer"
// import buyerEvent from "@src/views/pages/buyer-events/store/reducer"
import contacts from "@src/views/pages/contacts/store/reducer"
import ads from "@src/views/pages/ads/store/reducer"
import member_reports from "@src/views/pages/member_reports/store/reducer"
import polls from "@src/views/pages/polls/store/reducer"


const rootReducer = combineReducers({
  auth,
  users,
  navbar,
  layout,
  imports,
  booking,
  userBooking,
  bookingnew,
  booking3,
  bookingnew3,
  invites,
  authorizenet,
  password,
  // exhibitor,
  registration,
  speakerpanelist,
  abandoned,
  trash,
  // booths,
  aboutus,
  appSetting,
  event,
  category,
  emailtemplate,
  posts,
  //advertisement,
  pushs,
  //survey,
  //conferenceevent,
  membership,
  promoCode,
  categoryList,
  directory,
  organization,
  directoryui,
  //pollsui,
  userEvent,
  // buyerEvent,
  contacts,
  ads,
  member_reports,
  polls
  //product
})

export default rootReducer



// ** Initial State
const initialState = {
  allPosts: [],
  post: null,
}

const posts = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_POST':
      return { ...state, allPosts: action.data }
    case 'GET_DATA':
      return { ...state, post: action.data }
    default:
      return { ...state }
  }
}
export default posts

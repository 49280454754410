// ** Initial State
const initialState = {
  contacts: [],
  contactData: {},
};

const categoryList = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_CONTACTS":
      return { ...state, contacts: action.data };
    case "ADD_CONTACT":
      return { ...state, contactData: action.data };
    case "DELETE_CONTACT":
      return { ...state, data: action.data };
    case "GET_CONTACT":
      return { ...state, contactData: action.data };
    default:
      return { ...state };
  }
};
export default categoryList;

// ** Initial State
const initialState = {
  push: [],
  allPush: []
}

const pushs = (state = initialState, action) => {
  switch (action.type) { 
     
    case 'SENT_NOTIFICATON':
      return { ...state, push: action.data }

      case 'GET_NOTIFICATION':
        return { ...state, 
          allPush:  action.data }

      case 'DELETE_NOTIFICATION':
       return { ...state, 
        allPush:  action.data }

    default:
      return { ...state }
  }
}
export default pushs
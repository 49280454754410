// ** Initial State
const initialState = {
  allData: [],
  booking: null,
  pricing: []
}

const booking3 = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DATA':
      return { ...state, allData: action.data }
   
    case 'GET_BOOKING':
      return { ...state, booking: action.data }

    case 'GET_ALL_PRICING':
      return { ...state, pricing: action.data }

    default:
      return { ...state }
  }
}
export default booking3

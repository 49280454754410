// ** Initial State
const initialState = {
  allData: [],
  polls:[],
  data: null,
  pollsForList: {
    "limit": 10,
    "page": 0,
    "total_pages": null,
    "total_records": null,
    "data": null
  },
  pageLoader:true,
  pollsDetails: {},
  pollsQuestion: {},
}

const polls = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_POLLS_DATA_WITH_PAGINATION':
      return {...state, pollsForList: action.data, pageLoader:false}
    case 'CREATE_POLLS':
      return { ...state, polls: action.data }
    case 'GET_POLLS':
      return {...state, allData: action.data, pageLoader:false}
    case "GET_POLLS_DETAILS":
    return { ...state, pollsDetails: action.data };
    case "UPDATE_POLLS_DETAILS":
    return { ...state, pollsQuestion: action.data };
    default:
      return { ...state }
  }
}
export default polls

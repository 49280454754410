// ** Initial State
const initialState = {
    organization_details: [],
    organization: null,  
  }
  
  const organization = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_ORGANIZATION_DETAILS':
        return { ...state,organization_details: action.data }
      case 'UPDATE_ORGANIZATION_DETAILS':
        return {...state, organization: action.data }
      default:
        return { ...state }
    }
  }
  export default organization
  
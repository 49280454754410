// ** Initial State
const initialState = {
  directoryList: [],
  directoryListBasedOnType: [],
  directoryDetails: {},
  favouriteDetails: {},
};

const directoryui = (state = initialState, action) => {
  switch (action.type) {
    case "DIRECTORY_LIST":
      return { ...state, directoryList: action.data };
    case "DIRECTORY_LIST_BASED_ON_TYPE":
      console.log('data',action.data);
      return { ...state, directoryListBasedOnType: action.data };
    case "GET_DIRECTORY_DETAILS":
      return { ...state, directoryDetails: action.data };
    case "ADD_OR_UPDATE_FAVOURITE_SUBSCRIPTION":
      return { ...state, favouriteDetails: action.data };
    default:
      return { ...state };
  }
};
export default directoryui;

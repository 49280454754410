// ** Initial State
const initialState = {
  ads: [],
  adsData:{},
};

const adsList = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_ADS":
      return { ...state, ads: action.data };
    case "ADD_CONTACT":
      return { ...state, contactData: action.data };
    case "DELETE_CONTACT":
      return { ...state, data: action.data };
    case "GET_ADS":
      return { ...state, adsData:action.data };
    default:
      return { ...state };
  }
};
export default adsList;

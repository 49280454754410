// ** Initial State
const initialState = {
  promo_codes: [],
  promo_code: null,  
}

const promoCode = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_PROMO_CODE':
      return { ...state, promo_codes: action.data }
    case 'GET_DATA_PROMO_CODE':
      return { ...state,promo_code: action.data }
    case 'ADD_DATA_PROMO_CODE':
      return {...state, promo_code: action.data }
    default:
      return { ...state }
  }
}
export default promoCode

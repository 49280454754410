// ** Initial State
const initialState = {
  exhibitor: [],
  // complementry: [],
  allData:[],
  data:[],
  updateInvite:null,
  bulkEmail : [] 
}

const speakerpanelist = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_EXHIBITOR':
      return { ...state, exhibitor: action.data }
    case 'GET_ALL_DATA':
      return { ...state, allData: action.data }
    case 'GET_DATA':
      return { ...state, data: action.data }
    case 'SET_DATA':
      return { ...state, data: action.data }
    // case 'COMPLEMENT_CREATION':
    //   return { ...state, complementry: action.data }
    case 'DELETE_DATA':
      return { ...state, data: action.data }
    case 'UPDATE_INVITE':
      return { ...state, updateInvite: action.data }
    case 'SET_BULK_EMAIL':
      return { ...state, bulkEmail: action.data }
    default:
      return { ...state }
  }
}
export default speakerpanelist

// ** Initial State
const initialState = {
    category:[],
    maincategory:[],
    subcategory:[],
  }
  const category = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_ALL_CAT':
        return { ...state, category: action.data }
      case 'GET_MAIN_CAT':
        return { ...state, maincategory: action.data }
      default:
        return { ...state }
    }
  }
  export default category
  
// ** Initial State
const initialState = {
  allData: [],
  data: [],
  pricingData: [],
  membershipAllData:null,
  membershipEmailNotify:null,
  subscription:null,
  invoices:null,
  upcomingInvoice:null,
  loader:true
}

const membership = (state = initialState, action) => {
  switch (action.type) {
    case "BOOKING_LIST":
      return { ...state, allData: action.data };
    case "CREATE_MEMBERSHIP":
      return { ...state, data: action.data };
    case "MEMBERSHIP_LIST":
      return { ...state, membershipAllData: action.data };
    case "NOTIFYEMAIL_LIST":
      return { ...state, membershipEmailNotify: action.data };
    case "MEMBERSHIP_SUBSCRIPTION":
      return { ...state, subscription: action?.data, loader: false };
    case "MEMBERSHIP_INVOICE":
      return { ...state, invoices: action?.data, loader: false };
    case "MEMBERSHIP__UPCOMING_INVOICE":
      return { ...state, upcomingInvoice: action?.data, loader: false };
    case "LOADER":
      return { ...state, loader:action?.data };
    default:
      return { ...state };
  }
}
export default membership

// ** Initial State
const initialState = {
  directoryList: [],
  directoryListBasedOnType: [],
};

const directory = (state = initialState, action) => {
  switch (action.type) {
    case "DIRECTORY_LIST":
      return { ...state, directoryList: action.data };
    case "DIRECTORY_LIST_BASED_ON_TYPE":
      return { ...state, directoryListBasedOnType: action.data };
    default:
      return { ...state };
  }
};
export default directory;

// ** Initial State
const initialState = {
    list:[],
    visbility: null,
    users: null,
    event: [],
    section:[],
    ticket:[],
    agenda:[],
    category:[],
    // eventsection:[],
    // eventticket:[]


  }
  
  const event = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_EVENT_LIST':
        return { ...state, list: action.data }
      case 'TOGGLE_EVENT_VISIBILITY':
        return { ...state, visbility: action.data }
      case 'GET_EVENT_USERS_LIST':
        return { ...state, users: action.data }
      case 'CREATE_EVENT':
        return { ...state, event: action.data }
      case 'GET_EVENT':
        return { ...state, event: action.data }
      case 'GET_TICKET':
        return { ...state, ticket: action.data }  
      case 'GET_SECTION':
        return { ...state, section: action.data }
      case 'GET_AGENDA':
        return { ...state, agenda: action.data }   
      case 'GET_ALL_CATEGORY':
        return { ...state, category: action.data }    
      default:
        return { ...state }
    }
  }
  export default event
  
// ** Initial State
const initialState = {
  allData: [],
  data: null
}

const trash = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DATA':
      return { ...state, allData: action.data }

    case 'GET_USER_DATA':
        return { ...state, data: action.data }

  case 'TRUNCATE_USER_DATA':
        return { ...state, data: null }

    default:
      return { ...state }
  }
}
export default trash

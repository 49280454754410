// ** Initial State
const initialState = {
  categories: [],
  category_data: null,  
}

const categoryList = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_CATEGORY':
      return { ...state, categories: action.data }
    case 'GET_DATA_CATAGORY_CODE':
      return { ...state,category_data: action.data }
    default:
      return { ...state }
  }
}
export default categoryList

// ** Initial State
const initialState = {
  allData: [],
  data: [],
  params: {},
  
}

const aboutus = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DATA':
      return { ...state, allData: action.data }
    case 'GET_DATA':
      return {
        ...state,
        data: action.data,
        allData: action.data
      }
   
    case 'ADD_DATA':
      return {
        ...state,
        allData: [...state.allData, action.data]
      }
      
    case 'UPDATE_DATA':
      return {
        ...state,
        data: action.data,
      } 
    default:
      return { ...state }
  }
}
export default aboutus

// ** Initial State
const initialState = {
  allData: [],
  data: null,
  pricing: [],
  syncdata: [],
  exhibitor: [],
}

const registrationRecords = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_REG':
      return { ...state, allData: action.data }
   
    case 'GET_REG':
      return { ...state, data: action.data }
    case 'SET_REG':
      return { ...state, data: action.data }
    case 'NOTES':
      return { ...state, data: action.data }  
    case 'GET_ALL_PRICING':
      return { ...state, pricing: action.data }
    case 'DEL_REG':
      return { ...state, data: action.data }  
    case 'SYNC_USER_DATA':
        return { ...state, syncdata: action.data }
    case 'GET_EXHIBITOR':
        return { ...state, exhibitor:action.data}
        
    default:
      return { ...state }
  }
}
export default registrationRecords

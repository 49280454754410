// ** Initial State
const initialState = {
  exhibitor: [],
  exhibitorView: [],
  complementry: [],
  allData:[],
  data:[],
  updateInvite:null,
  additional: [],

}

const invites = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_EXHIBITOR':
      return { ...state, exhibitor: action.data }
    case 'GET_EXHIBITOR_VIEW':
      return { ...state, exhibitorView: action.data }
    case 'GET_ADDITIONAL_ATTENDEES':
      return { ...state, additional: action.data }
    case 'SET_ADDITIONAL_ATTENDEES':
      return { ...state, additional: action.data }
    case 'GET_ALL_DATA':
      return { ...state, allData: action.data }
    case 'COMPLEMENT_CREATION':
      return { ...state, complementry: action.data }
    case 'DELETE_DATA':
      return { ...state, data: action.data }
    case 'UPDATE_INVITE':
      return { ...state, updateInvite: action.data }
    case 'SET_UPDATE_INVITE':
      return { ...state, updateInvite: action.data }

    default:
      return { ...state }
  }
}
export default invites

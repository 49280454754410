const initialState = {
  paymentResp: []
}

const authorizenetToken = (state = initialState, action) => {
  switch (action.type) {
    case 'PAYMENT_RESPONSE':
      return { ...state, paymentResp: action.data }

    default:
      return { ...state }
  }
}
export default authorizenetToken
